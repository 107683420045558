import { Injectable } from '@angular/core';
import { measure } from 'measurement-protocol';
import { PlatformServiceService } from '../services/platform-service.service';
import { Router, ActivatedRoute } from '@angular/router';
import * as dtBrowser from 'detect-browser';
import { v4 as uuidv4 } from 'uuid';
import { environment } from '../../environments/environment';
const { version } = require('../../../../../package.json');
declare var ddsc: any;

@Injectable({
  providedIn: 'root'
})
export class GATrackerService {
  private userFrom: string = '';
  private appVersion: string = '';
  private userLanguage: string = '';
  private basicParms: any;
  private isEnabled: boolean = (version !== "0.0.0");
  private tracker: any;
  private native_trim: any;
  userData: string[];
  private regPath = 'SOFTWARE\\MultiTrim';
  private hKey: any;
  private GetRegistStringType: any;
  private GetRegistDWORDType: any;
  private GetRegistDiskInfo: any;
  private GetRegBIOSInfo: any;
  private CPU: any;
  private bReady = false;
  private GaQueue = [];

  constructor(
    private plats: PlatformServiceService,
    private router: Router,
    private activatedRoute: ActivatedRoute
  ) {

    if (!this.isEnabled)
      console.log('GA is disabled');

    if (this.plats.isDesktopApp) {
      this.native_trim = this.plats.getDesktopModule('native_trim');
      this.userFrom = ddsc.admin.getUserFrom()
      this.userLanguage = ddsc.admin.getUserLanguage();
      this.appVersion = version;
      this.basicParms = Object.assign({}, {
        v: '1',
        cid: ddsc.admin.getUUID(),
        // an: 'Multi-Trim',
        av: this.appVersion,
        ul: this.userLanguage,
        ds: this.userFrom,
        cm: this.userFrom,
        cs: this.userFrom,
      })

      enum hKey {
        HKEY_CLASSES_ROOT = 0,
        HKEY_CURRENT_USER,
        HKEY_LOCAL_MACHINE,
      }

      this.GetRegistStringType = function (Key: any) {
        const registryValue = this.native_trim.getRegistryStringValue(hKey.HKEY_CURRENT_USER, `${this.regPath}\\User`, Key);
        return registryValue;
      }

      this.GetRegistDWORDType = function (Key: any) {
        const registryValue = this.native_trim.getRegistryDWORDValue(hKey.HKEY_CURRENT_USER, `${this.regPath}\\User`, Key);
        return registryValue;
      }
      this.GetRegistDiskInfo = function (Key: any) {
        const registryValue = this.native_trim.getRegistryStringValue(hKey.HKEY_CURRENT_USER, `${this.regPath}\\User\\Disk0`, Key);
        return registryValue;
      }

      this.GetRegBIOSInfo = function (Key: any) {
        const registryValue = this.native_trim.getRegistryStringValue(hKey.HKEY_LOCAL_MACHINE, 'HARDWARE\\DESCRIPTION\\System\\BIOS', Key);
        return registryValue;
      }

      this.CPU = this.native_trim.getRegistryStringValue(hKey.HKEY_LOCAL_MACHINE, 'HARDWARE\\DESCRIPTION\\System\\CentralProcessor\\0', 'ProcessorNameString');


      //this.CollectUserData();

    } else {
      const browser: any = dtBrowser.detect();
      this.userFrom = `${browser.name}`.toUpperCase();
      this.appVersion = version;
      this.userLanguage = navigator.language;
      this.basicParms = Object.assign({}, {
        v: '1',
        cid: uuidv4(),
        av: this.appVersion,
        ul: this.userLanguage,
        ds: this.userFrom,
        cm: this.userFrom,
        cs: this.userFrom,
      })
    }
  }

  PrepareDataForGA() {

    if (this.plats.isDesktopApp) {
      if (!this.native_trim)
        return false;

      let UserOSBit = this.native_trim.getUserData('OS_Bit');
      let paltform = 'Null';
      if (UserOSBit.toString() === '64') {
        paltform = 'x64';
      } else {
        paltform = 'x86';
      }
      UserOSBit = UserOSBit + '-bits';

      let modelName = this.GetRegBIOSInfo('SystemProductName');//model name
      if (modelName == 'System Porduct Name' || modelName == '') {
        modelName = 'BIOS no Product Name';
      }
      const country = this.native_trim.getUserData('Country');
      const language = this.native_trim.getUserData('Language');//language
      const brand = this.GetRegBIOSInfo('SystemManufacturer');//brand
      const SerialNumber = this.native_trim.getUserData('BIOSSerialNumber');//sn
      const GUID = this.native_trim.getUserData('Guid');//GUID
      const DisplaySize = this.native_trim.getUserData('DisplaySize');//display size
      const os = this.native_trim.getUserData('OS');//os
      const osVer = this.GetRegistDWORDType('OS_Version'); //os version
      const osBit = UserOSBit; //os bit
      const RAM = this.native_trim.getUserData('RAM'); //ram
      const graphicsCard = this.native_trim.getUserData('GraphicsCard');
      const storage = this.native_trim.getUserData('Disk');
      let appVer = this.appVersion;//version number
      let dispatch = `${this.userFrom}`;//dispatch channel

      this.userData = [
        modelName, country, language, brand, paltform,
        SerialNumber, GUID, DisplaySize, os, osVer, osBit, RAM, graphicsCard, storage, this.CPU, appVer, dispatch];

      this.bReady = true;

      this.GaQueue.forEach(value => {
        measure(this.getGAMeasurementID(), value).setCustomDimensions(this.userData).send();
      })
      this.GaQueue = [];

      return true;
    }

    return true;

  }


  getGAMeasurementID() {
    return environment.gaTrackingId;
  };

  PageView_Send(pageTitle: string) {
    if (!this.isEnabled)
      return;
    //const currPage = window.location.href;
    //const pagepathElements = currPage.split('/');
    //const pageTitle = pagepathElements[pagepathElements.length - 1];

    console.log(`GA Pageview:${window.location.href},pageTitle:${pageTitle}`)

    let basicParms = JSON.parse(JSON.stringify(this.basicParms)); // deepcopy
    const pageViewParms = Object.assign(basicParms, { t: 'pageview', dl: window.location.href, dt: pageTitle, an: name })

    if (this.plats.isDesktopApp) {
      if (this.bReady && (typeof this.userData !== 'undefined'))
        measure(this.getGAMeasurementID(), pageViewParms).setCustomDimensions(this.userData).send();
      else {
        console.log(`ga push pageViewParms to queue:${pageViewParms}`);
        this.GaQueue.push(pageViewParms);
      }
    }
    else
      measure(this.getGAMeasurementID(), pageViewParms).send();
  }

  Event_Send(Catagory: string, Action: string, Label: string, Value?: number) {
    if (!this.isEnabled)
      return;

    let eventParms;
    let basicParms = JSON.parse(JSON.stringify(this.basicParms)); // deepcopy
    if (typeof Value !== 'undefined' && Value >= 0) {
      // with value
      eventParms = Object.assign(basicParms, { t: 'event', ec: Catagory, ea: Action, el: `${Label}(${this.userFrom})`, ev: Value, an: name });
    } else {
      // without value
      eventParms = Object.assign(basicParms, { t: 'event', ec: Catagory, ea: Action, el: `${Label}(${this.userFrom})`, an: name });
    }

    if (this.plats.isDesktopApp) {
      if (this.bReady && (typeof this.userData !== 'undefined'))
        measure(this.getGAMeasurementID(), eventParms).setCustomDimensions(this.userData).send();
      else {
        this.GaQueue.push(eventParms);
      }
    }
    else
      measure(this.getGAMeasurementID(), eventParms).send();
  }

}
