import { Inject, Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, RouterStateSnapshot, UrlTree } from '@angular/router';
import { LocalStorageService } from 'ngx-webstorage';
import { Observable } from 'rxjs';
import { environment } from 'uxauth';

@Injectable()
export class StagGuardService implements CanActivate {
  stag;
  constructor(
    @Inject(environment) env,
    private _storageService: LocalStorageService
  ) {
    this.stag = !env.production;
  }
  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): boolean | UrlTree | Observable<boolean | UrlTree> | Promise<boolean | UrlTree> {

    if (window.location.host == "localhost:4200")
      return true;

    if (this.stag) {
      var d = new Date();
      var rightNow = new Date();
      var res = rightNow.toISOString().slice(0, 10).replace(/-/g, "");
      var authCode = this._storageService.retrieve('authCode');
      if (authCode == res)
        return true;
      authCode = window.prompt();
      this._storageService.store('authCode', authCode);
      return authCode == res;
    }

    return true;
  }
}
