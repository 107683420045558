import { Component, OnInit, ChangeDetectionStrategy, Inject, ViewChild, ElementRef } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { SignUpComponent } from '../sign-up/sign-up.component';
import { PlatformServiceService } from 'uxshared';
import { Router } from '@angular/router';
import { AuthBehaviorService, AuthService, environment, LoginStatus } from 'uxauth';
import { EventTrackingService, GA_LoginMethod } from 'projects/uxshared/src/lib/services/event-tracking.service';

declare let gtag: Function;
@Component({
  selector: 'lib-sign-in',
  templateUrl: './sign-in.component.html',
  styleUrls: ['./sign-in.component.scss']
})
export class SignInComponent implements OnInit {
  password = '';
  email = '';
  loginStatus = LoginStatus;
  forgetPassword: string;
  hide = true;
  @ViewChild('SignPopup') signPopup: ElementRef;
  constructor(
    public authService: AuthService,
    public signInPage: MatDialogRef<SignInComponent>,
    public pfss: PlatformServiceService,
    private authBehavior: AuthBehaviorService,
    private eventTracking: EventTrackingService,
    @Inject(environment) private env
  ) {
    const url = new URL(this.env.apiUrl);
    this.forgetPassword = `${url.origin}/Identity/Account/ForgotPassword`;
  }

  ngOnInit(): void {
    this.eventTracking.trackPageView('sign-in');
  }
  login(): void {
    if (this.email && this.password) {
      this.eventTracking.trackEvent('Auth', 'ButtonClick', `${GA_LoginMethod.mail}`);
      this.authService.loginUser(this.email, this.password).subscribe((res) => {
        // console.log(res);
        this.closeSignUpPage();
      });
    }
  }

  openSignUpPage(): void {
    this.eventTracking.trackEvent('Auth', 'ButtonClick', 'OpenSignUp');
    this.authBehavior.openSignUpPage();
  }
  closeSignUpPage(): void {
    this.eventTracking.trackEvent('Auth', 'ButtonClick', 'CloseSignUp');
    this.authBehavior.closePage();
  }
  forgotPW() {
    this.eventTracking.trackEvent('Auth', 'ButtonClick', 'ForgotPassword');
  }
  close(): void {
    this.authBehavior.closePage();
  }
  async googleLogin(): Promise<void> {
    gtag('event', 'conversion', {
      'send_to': 'AW-310968363/m8J8COu7kfgCEKuApJQB'
    });
    this.authService.loginUserByGoogle(this.authBehavior, this.signPopup.nativeElement).subscribe((res) => {
      console.log(res);
      this.eventTracking.trackEvent('Auth', 'ButtonClick', `${GA_LoginMethod.google}`);
      this.authBehavior.closePage();
      this.authBehavior.focusMainWindow();
    });
  }
  async microsoftLogin(): Promise<void> {
    this.authService.loginUserByMicrosoft(this.authBehavior, this.signPopup.nativeElement).subscribe((res) => {
      console.log(res);
      this.eventTracking.trackEvent('Auth', 'ButtonClick', `${GA_LoginMethod.microsoft}`);
      this.authBehavior.closePage();
      this.authBehavior.focusMainWindow();
    });
  }
  async appleLogin(): Promise<void> {
    gtag('event', 'conversion', {
      'send_to': 'AW-310968363/dZViCLbCrv4CEKuApJQB'
    });
    this.authService.loginUserByApple(this.authBehavior, this.signPopup.nativeElement).subscribe((res) => {
      console.log(res);
      this.eventTracking.trackEvent('Auth', 'ButtonClick', `${GA_LoginMethod.apple}`);
      this.authBehavior.closePage();
      this.authBehavior.focusMainWindow();
    });
  }
}
