export const environment = {
  production: false,
  gaTrackingId: 'UA-199902873-2',
  apiUrl: "https://user-system.azurewebsites.net/api",
  appleIdUrl: "https://user-system.azurewebsites.net/api/users/apple_login",
  multiroUrl: "https://stagging.multiro.io",
  messageUrl:"https://ulictekwebappsmessage.azureedge.net/message/v1",
  winStoreProductUrl: "https://www.microsoft.com/store/apps/9NZC1XJCNB76",
  cutterWeb: "https://stagging.multiro.io/cutter/?answer=ulic",
  appleStoreProductUrl: "https://apps.apple.com/us/app/multiro-cutter/id1582291768",
  webBaseUrl: "https://stagging.multiro.io/?answer=ulic",
  downloadBaseUrl: "https://ulictekwebappsmessage.azureedge.net/download"
};
