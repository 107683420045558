import { Component, OnInit } from '@angular/core';
import { AuthBehaviorService, AuthService } from 'uxauth';
import { PlatformServiceService } from 'uxshared';

@Component({
  selector: 'lib-verify-email',
  templateUrl: './verify-email.component.html',
  styleUrls: ['./verify-email.component.scss']
})
export class VerifyEmailComponent implements OnInit {

  constructor(
    public plats: PlatformServiceService,
    public authBehavior: AuthBehaviorService,
    public authService: AuthService
  ) { }

  ngOnInit(): void {
  }
  openSignin(): void {
    this.authBehavior.closePage();
    this.authBehavior.openSignInPage();
  }

  close(): void{
    this.authBehavior.closePage();
  }


  resendEmail(): void{
    const user = this.authService.usersModel.getCacheUser();
    this.authService.resendEmailConfirmation(user.email)
      .subscribe((res) => {
        alert('email is resend');
        console.log(res)
      });
  }
}
