<header>
  <div class="headerfooterBlock">
    <div class="headerParagraph" (click)="goMultiroWeb()">Multiro</div>
    <div class="headerLinkDiv">

      <a href="mailto:support@multiro.io">Contact</a>
      <a href="help" target="_parent">Support</a>
      <a id="dl_link" (click)="goDownload()">Download</a>
    </div>

  </div>
</header>


<div class="appFirstDiv">
  <div class="appDivClamp">
    <div class="appDivDesc">
      <h1>Trim your video instantly & smartly</h1>
      <h3>Amazingly convenient free video cutter for your PC, Mac, and Chromebook.</h3>
      <h3>With Auto Cut by Sound & Motion!</h3>

      <div class="btnDiv">
        <div class="tryOnlineBtn" (click)="goWebVersion()">Trim online</div>
      </div>

    </div>
    <div class="appIconContainer">
      <img src="../../multiro_assets/imgs/cutter-icon.svg">
      <p>Multiro Cutter</p>
    </div>
  </div>
</div>
<div *ngIf="!isPord">
  <h1>Adsense</h1>
  <script async src="https://pagead2.googlesyndication.com/pagead/js/adsbygoogle.js?client=ca-pub-3695969026586206"
    crossorigin="anonymous"></script>
  <!-- multiro-ad -->
  <ins class="adsbygoogle" style="display:block" data-ad-client="ca-pub-3695969026586206" data-ad-slot="6703730919"
    data-ad-format="auto" data-full-width-responsive="true"></ins>
  <script>
    (adsbygoogle = window.adsbygoogle || []).push({});
  </script>
</div>
<section id="download">
  <div class="appSecDiv">
    <div class="appDivClamp">
      <div class="appSecDivDesc">
        <h2>Get Multiro Cutter for Desktop and sign in to enjoy more features!</h2>
        <div class="downloadIconBlock">
          <div class="appBtnBlock">
            <a href={{dmgUrl}}
              target="_parent">
              <img id="macDownload" src="../../multiro_assets/imgs/downloadmac_normal.svg" width="191px" height="42px">
            </a>
            <p>version : 1.1.1422</p>
          </div>

          <div class="appBtnBlock">
            <a href="https://www.microsoft.com/store/apps/9NZC1XJCNB76" target="_blank">
              <img src="../../multiro_assets/imgs/MS_ICON.svg" width="111px" height="40px">
            </a>
            <p>version : 1.1.1422</p>
          </div>
        </div>
      </div>
    </div>
  </div>

</section>


<div class="appThirdDiv">
  <div class="appDivClamp">
    <div class="appThirdDivContents">
      <h2>Multiro Cutter Web vs. Desktop</h2>
      <table class="tg">
        <thead>
          <tr class="theadClass">
            <td class="tg-header-normal" width="50%" [style.padding-left.px]=83 rowspan="2">Feature</td>
            <!-- <td class="tg-header-bold" width="50%" colspan="2">Multiro Cutter</td> -->
          </tr>
          <tr class="theadClass">
            <td class="tg-header-normal" width="25%">
              <p>Web</p>
            </td>
            <td class="tg-header-normal" width="25%">
              <p>Desktop</p>
            </td>
          </tr>
        </thead>
        <tr class="tbodyClass">
          <td class="tg-title">Auto Cut by Sound</td>
          <td class="tg-yes">
            <div class="yesDiv">
              <div class="correctImgBlock">
                <img src="../../multiro_assets/imgs/correct-sign.svg">
              </div>
              <p>Yes</p>
            </div>
          </td>
          <td class="tg-yes">
            <div class="yesDiv">
              <div class="correctImgBlock">
                <img src="../../multiro_assets/imgs/correct-sign.svg">
              </div>
              <p>Yes</p>
            </div>
          </td>
        </tr>

        <tr class="tbodyClass">
          <td class="tg-title">Auto Cut by Motion</td>
          <td class="tg-yes">
            <div class="yesDiv">
              <div class="correctImgBlock">
                <img src="../../multiro_assets/imgs/correct-sign.svg">
              </div>
              <p>Yes</p>
            </div>
          </td>
          <td class="tg-yes">
            <div class="yesDiv">
              <div class="correctImgBlock">
                <img src="../../multiro_assets/imgs/correct-sign.svg">
              </div>
              <p>Yes</p>
            </div>
          </td>
        </tr>

        <tr class="tbodyClass">
          <td class="tg-title">Split Video</td>
          <td class="tg-yes">
            <div class="yesDiv">
              <div class="correctImgBlock">
                <img src="../../multiro_assets/imgs/correct-sign.svg">
              </div>
              <p>Yes</p>
            </div>
          </td>
          <td class="tg-yes">
            <div class="yesDiv">
              <div class="correctImgBlock">
                <img src="../../multiro_assets/imgs/correct-sign.svg">
              </div>
              <p>Yes</p>
            </div>
          </td>
        </tr>

        <tr class="tbodyClass">
          <td class="tg-title">Export XML</td>
          <td class="tg-yes">
            <div class="yesDiv">
              <div class="correctImgBlock">
                <img src="../../multiro_assets/imgs/correct-sign.svg">
              </div>
              <p>Yes*</p>
            </div>
          </td>
          <td class="tg-yes">
            <div class="yesDiv">
              <div class="correctImgBlock">
                <img src="../../multiro_assets/imgs/correct-sign.svg">
              </div>
              <p>Yes*</p>
            </div>
          </td>
        </tr>

        <tr class="tbodyClass">
          <td class="tg-title">Export WebM</td>
          <td class="tg-yes">
            <div class="yesDiv">
              <div class="correctImgBlock">
                <img src="../../multiro_assets/imgs/correct-sign.svg">
              </div>
              <p>Yes*</p>
            </div>
          </td>
          <td class="tg-yes">
            <div class="yesDiv">
              <div class="correctImgBlock">
                <img src="../../multiro_assets/imgs/correct-sign.svg">
              </div>
              <p>Yes*</p>
            </div>
          </td>
        </tr>

        <tr class="tbodyClass">
          <td class="tg-title">Export MP4/MOV</td>
          <td class="tg-no">
            <div class="noDiv">
              <div class="correctImgBlock"></div>
              <p>No</p>
            </div>

          </td>
          <td class="tg-yes">
            <div class="yesDiv">

              <div class="correctImgBlock">
                <img src="../../multiro_assets/imgs/correct-sign.svg">
              </div>
              <p>Yes*</p>
            </div>
          </td>
        </tr>

        <tr class="tbodyClass">
          <td class="tg-title">Save Project</td>
          <td class="tg-no">
            <div class="noDiv">
              <div class="correctImgBlock"></div>
              <p>No</p>
            </div>
          </td>
          <td class="tg-yes">
            <div class="yesDiv">
              <div class="correctImgBlock">
                <img src="../../multiro_assets/imgs/correct-sign.svg">
              </div>
              <p>Yes*</p>
            </div>
          </td>
        </tr>
        <tbody>

        </tbody>
      </table>
      <div class="noticeDiv">
        <h6>* Sign in required.</h6>
        <!-- <div class="clickInk">
          <a href="https://www.microsoft.com/store/apps/9NZC1XJCNB76" target="_blank">Click here</a>
          <span> to visit Microsoft Store and get the latest PC version of Multiro Cutter.</span>
        </div> -->
      </div>

    </div>

  </div>


</div>



<footer>
  <div class="headerfooterBlock">
    <div class="footCopyrightParagraph">
      <span>Copyright © 2021 </span>
      <a href="https://www.ulicdigital.com" target="_blank">ULIC Digital Inc. </a>
      <span>All rights reserved.</span>
    </div>
    <div class="footLinkParagraph">
      <a (click)='this.goMultiroWeb()'>Home</a>
      <span>&emsp;|&emsp;</span>
      <a href="terms">Terms of Use</a>
      <span>&emsp;|&emsp;</span>
      <a href="privacy">Privacy Policy</a>
      <div class="footMailToParagraph" (click)="sendMail()"
        (mouseover)="imgSrc = '../../multiro_assets/imgs/mail_dark.svg'"
        (mouseout)="imgSrc = '../../multiro_assets/imgs/mail_light.svg'">
        <img [src]="imgSrc">
        support@multiro.io
      </div>
    </div>



  </div>
</footer>
