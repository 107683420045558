import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { PlatformServiceService } from 'uxshared';

@Injectable()
export abstract class AuthBehaviorService {
  abstract SignInPage?;
  abstract SignUpPage?;
  abstract authWindow?;
  abstract say(): void;
  abstract openSignInPage(): void;
  abstract closePage(): void;
  abstract actionLogout(): void;
  abstract openSignUpPage(): void;
  abstract openVerifyEmailPage(): void;
  abstract openExtlogin(url: string, signPopup): Observable<any>;
  abstract focusMainWindow(): void;
  abstract openSetting(url: string): void;
}
