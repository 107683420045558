<link rel="stylesheet" href="https://cdn.jsdelivr.net/npm/bootstrap-icons@1.3.0/font/bootstrap-icons.css" />

<div #SignPopup class="sign-in" [ngClass]="{'sign-in_desktop': pfss.isDesktopApp}">

   <div class="sign-in__title">
    <div class="sign-in__title-txt">
      <img src="multitrim_assets/images/sign-page/multiroIcon.svg" draggable="false">
      <span>{{"SIGNINDIALOG.MULTIRO"|translate}}</span>
    </div>

    <img class="sign-in__close" src="multitrim_assets/images/sign-page/sign_close.svg" (click)="close()" draggable="false">
  </div>
  <!--
  <div class="sign-in__login">
    <label class="itemLabel">{{"SIGNINDIALOG.EMAILADDRESS"| translate}}</label>
    <div class="sign-in__row sign-in__email">
      <mat-form-field>
        <input type="email" matInput [(ngModel)]="email" name="email" required>
      </mat-form-field>
    </div>
    <label class="itemLabel">{{"SIGNINDIALOG.PASSWORD"|translate}}</label>

    <div class="sign-in__row sign-in__password">
      <mat-form-field>
        <input [type]="hide ? 'password' : 'text'" matInput [(ngModel)]="password" name="password" required>
        <div class="seeDiv">
          <img src="multitrim_assets/images/sign-page/closeEye.svg" *ngIf="hide===true" draggable="false"
            (click)="hide = !hide">
          <img src="multitrim_assets/images/sign-page/openEye.svg" *ngIf="hide===false" draggable="false"
            (click)="hide = !hide">
        </div>
      </mat-form-field>

    </div>
    <div class="sign-in__keeploginBlock">
      <input type="checkbox" checked="checked">
      <label>{{"SIGNINDIALOG.STAYSIGNIN"|translate}}</label>
    </div>

    <div class="sign-in__actionBlock" (click)="forgotPW()">
      <a [href]="forgetPassword" target="_blank"
        class="sign-in__forget-password">{{"SIGNINDIALOG.FORGOTPASSWORD"|translate}}</a>
      <button class="sign-in__loginBtn" (click)="login()"
        [disabled]="this.authService.loginStatus===loginStatus.WaitLogin"> {{"SIGNINDIALOG.SIGNIN"|translate}}</button>
    </div>
  </div>
  <div class="sign-in__sign-up">
    <p>
      {{"SIGNINDIALOG.NOTREGISTER" | translate}}
    </p>
    <button class="sign-in__signupBtn" (click)="openSignUpPage()"
      [disabled]="this.authService.loginStatus===loginStatus.WaitLogin">{{"SIGNUPDIALOG.SIGNUP"|translate}}</button>
  </div> -->
  <div class="sign-in__use3party">
    <!-- <div class="sign-in__use3party__title">
      <p>
        {{"SIGNINDIALOG.OTHERWAYS"|translate}}
      </p>
    </div> -->
    <div class="sign-in__use3partyBtnsBlock">
      <button class="sign-in__3-party-btn sign-in__3-party-btn_google" (click)="googleLogin()" >
        <div class="sign-in__3-party-logo">
          <img src="uxauth_assets/icon/google-logo.png" draggable="false">
        </div>
        <span class="sign-in__3-party-txt">
          {{"SIGNINDIALOG.SIGN_IN_GOOGLE"|translate}}
        </span>
      </button>
      <button class="sign-in__3-party-btn sign-in__3-party-btn_microsoft" (click)="microsoftLogin()" >
        <div class="sign-in__3-party-logo">
          <img src="uxauth_assets/icon/microsoft-logo.png" draggable="false">
        </div>
        <span class="sign-in__3-party-txt">
          {{"SIGNINDIALOG.SIGN_IN_MICROSOFT"|translate}}
        </span>
      </button>
      <button class="sign-in__3-party-btn sign-in__3-party-btn_apple" (click)="appleLogin()">
        <!-- <img class="sign-in__apple-dark" src="uxauth_assets/icon/appleid_button_dark.png" draggable="false">
        <img class="sign-in__apple-light" src="uxauth_assets/icon/appleid_button_light.png" draggable="false"> -->
        <div class="sign-in__3-party-logo">
          <img class="sign-in__apple-dark" src="uxauth_assets/icon/apple_icon_dark.svg" draggable="false">
          <img class="sign-in__apple-light" src="uxauth_assets/icon/apple_icon_light.svg" draggable="false">
        </div>
        <span class="sign-in__3-party-txt">
          {{"SIGNINDIALOG.SIGN_IN_APPLE"|translate}}
        </span>
      </button>
    </div>
  </div>
</div>
