import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { AuthBehaviorService, AuthService, FormRule, LoginStatus, VerifyEmailComponent } from 'uxauth';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { PlatformServiceService } from 'uxshared';
import { Router } from '@angular/router';
import { passwordMustMatch } from '../../passwordMustMatch';
import { EventTrackingService, GA_LoginMethod } from 'projects/uxshared/src/lib/services/event-tracking.service';
import { environment } from 'projects/uxshared/src/environments/environment';

enum ErrorCode {
  password = 'passwordNotMatch',
  email = 'emailError',
}

@Component({
  selector: 'lib-sign-up',
  templateUrl: './sign-up.component.html',
  styleUrls: ['./sign-up.component.scss']
})
export class SignUpComponent implements OnInit {

  // tslint:disable-next-line: deprecation
  signUpForm: FormGroup;
  loginStatus = LoginStatus;
  errorCode = ErrorCode;
  nameRule = [Validators.required, Validators.pattern(FormRule.Name)];
  passwordRule = [Validators.required, Validators.pattern(FormRule.Password)];
  hide = true;
  @ViewChild('SignPopup') signPopup: ElementRef;
  submitDisable = false;
  constructor(
    public authService: AuthService,
    public signUpPage: MatDialogRef<SignUpComponent>,
    public pfss: PlatformServiceService,
    private authBehavior: AuthBehaviorService,
    private eventTracking: EventTrackingService,
  ) {
    this.signUpForm = new FormGroup({
      email: new FormControl('', [Validators.required, Validators.pattern(FormRule.Email)]),
      password: new FormControl('', this.passwordRule),
      // ConfirmPassword: new FormControl('', this.passwordRule),
      firstname: new FormControl('', this.nameRule),
      lastname: new FormControl('', this.nameRule),
      optIn: new FormControl(true)
    },
      {
        validators: [
          // passwordMustMatch('Password', 'ConfirmPassword')
        ]
      });

  }



  ngOnInit(): void {
    // console.log(this.signUpForm);
    this.eventTracking.trackPageView('sign-up');
  }
  close(): void {
    this.authBehavior.closePage();
  }

  TermsAndConditions() {
    this.eventTracking.trackEvent('Auth', 'ButtonClick', 'TermsAndConditions');
  }
  PrivacyPolicy() {
    this.eventTracking.trackEvent('Auth', 'ButtonClick', 'PrivacyPolicy');
    var url = `${environment.multiroUrl}/privacy`;
    if (PlatformServiceService.instance.isDesktopApp) {
      const shell = window.require('electron').shell;
      shell.openExternal(url)
    } else {
      window.open(url);
    }
  }

  submitForm(): void {

    if (this.signUpForm.status === 'VALID') {
      this.eventTracking.trackEvent('Auth', 'ButtonClick', 'SubmitForm');
      // console.log(this.signUpForm)
      // tslint:disable-next-line: deprecation
      this.submitDisable = true;
      this.authService.registerUser(this.signUpForm.value).subscribe(
        (res) => {
          try {
            this.authBehavior.openVerifyEmailPage();
            this.submitDisable = false;
          } catch (error) {
            console.error(error);
          }
        },
        (err) => {
          this.submitDisable = false;
          console.error(err);
        }
      );
    }
  }

  openSignin(): void {
    this.authBehavior.closePage();
    this.authBehavior.openSignInPage();
    this.eventTracking.trackEvent('Auth', 'ButtonClick', 'OpenSignIn');
  }

  async googleLogin(): Promise<void> {
    this.authService.loginUserByGoogle(this.authBehavior, this.signPopup.nativeElement).subscribe((res) => {
      console.log(res);
      this.eventTracking.trackEvent('Auth', 'ButtonClick', `${GA_LoginMethod.google}`);
      this.authBehavior.closePage();
      this.authBehavior.focusMainWindow();
    });
  }
  async microsoftLogin(): Promise<void> {
    this.authService.loginUserByMicrosoft(this.authBehavior, this.signPopup.nativeElement).subscribe((res) => {
      console.log(res);
      this.eventTracking.trackEvent('Auth', 'ButtonClick', `${GA_LoginMethod.microsoft}`);
      this.authBehavior.closePage();
      this.authBehavior.focusMainWindow();
    });
  }
  async appleLogin(): Promise<void> {
    this.authService.loginUserByApple(this.authBehavior, this.signPopup.nativeElement).subscribe((res) => {
      console.log(res);
      this.eventTracking.trackEvent('Auth', 'ButtonClick', `${GA_LoginMethod.apple}`);
      this.authBehavior.closePage();
      this.authBehavior.focusMainWindow();
    });
  }
}
