import { MatDialog } from '@angular/material/dialog';
import { Component, OnInit, ViewChild, ElementRef, NgZone, OnDestroy } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { AuthBehaviorService, AuthService, IPC_MESSAGES, LoginStatus, UserStatus, VerifyEmailComponent } from 'uxauth';
import { LanguageService, PlatformServiceService } from 'uxshared';
const { version } = require('../../../../package.json');
declare var ddsc: any;

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit, OnDestroy {
  title = 'multiro';
  connetStauts: boolean = false;
  userProfile: any;
  popupUpdate: boolean = false;
  expiredPopup = false;
  logoutPopup = false;
  popupExpired = false;
  constructor(
    private authService: AuthService,
    private authBehavior: AuthBehaviorService,
    private languageService: LanguageService,
  ) {
    // tslint:disable-next-line: deprecation
    this.authService.subject.subscribe(msg => {
      switch (msg.status) {
        case UserStatus.UserLogin:
          this.authService.loginStatus = LoginStatus.Login;
          this.authBehavior.closePage();
          this.authService.setUserProfile();
          break;
        case UserStatus.UserReLogin:
          this.authService.loginStatus = LoginStatus.Login;
          this.authBehavior.closePage();
          this.authService.setUserProfile();
          break;
        case UserStatus.UserLoginByThirdParty:
          this.authService.loginStatus = LoginStatus.Login;
          this.authBehavior.closePage();
          this.authService.setUserProfile();
          break;
        case UserStatus.UserReLoginByThirdParty:
          this.authService.loginStatus = LoginStatus.Login;
          this.authBehavior.closePage();
          this.authService.setUserProfile();
          break;
        case UserStatus.UserLogout:
          this.authService.loginStatus = LoginStatus.Logout;
          break;
        default:
          break;
      }
    });
  }

  ngOnInit() {
    this.languageService.setInitState();

  }

  ngOnDestroy() {
    this.authService.subject.unsubscribe();
  }

  get version() {
    return version;
  }

}
