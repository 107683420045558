<div #SignPopup class="sign-up" [ngClass]="{'sign-up_desktop': pfss.isDesktopApp}">
  <div class="sign-up__title">
    <div class="sign-up__title-txt">
      {{"SIGNUPDIALOG.SIGNUP"|translate}}
    </div>
    <img src="multitrim_assets/images/sign-page/sign_close.svg" (click)="close()" draggable="false">
  </div>

  <form class="sign-up__register" [formGroup]="signUpForm" novalidate>

    <div class="sign-up__row">
      <label class="itemLabel">{{"SIGNINDIALOG.EMAILADDRESS"| translate}}</label>
      <mat-form-field>
        <input type="email" matInput name="email" formControlName="email">
        <div *ngIf="signUpForm.get('email').touched && signUpForm.get('email').hasError('required')"
          class="sign-up__error-message">
          <i>
            !
          </i>
          <span>
            Required
          </span>
        </div>
        <div *ngIf="signUpForm.get('email').hasError('pattern')" class="sign-up__error-message">
          <i>
            !
          </i>
          <span>
            Invalid email
          </span>
        </div>
      </mat-form-field>
    </div>

    <div class="sign-up__passwordRow">
      <label class="itemLabel">{{"SIGNINDIALOG.PASSWORD"|translate}}</label>
      <mat-form-field>
        <input [type]="hide ? 'password' : 'text'" matInput name="password" formControlName="password">
        <div class="seeDiv">
          <img src="multitrim_assets/images/sign-page/closeEye.svg" *ngIf="hide===true" draggable="false"
            (click)="hide = !hide">
          <img src="multitrim_assets/images/sign-page/openEye.svg" *ngIf="hide===false" draggable="false"
            (click)="hide = !hide">
        </div>
        <!-- <mat-error *ngIf="signUpForm.get('Password').hasError('required')">
          Please enter your new password
        </mat-error>
        <mat-error *ngIf="signUpForm.get('Password').hasError('pattern')">
          Password need 8-20 characters and an uppercase letter and a lowercase letter and a number
        </mat-error> -->
        <div *ngIf="signUpForm.get('password').touched && signUpForm.get('password').hasError('required')"
          class="sign-up__error-message">
          <i>
            !
          </i>
          <span>
            Required
          </span>
        </div>
        <div *ngIf="signUpForm.get('password').hasError('pattern')" class="sign-up__error-message">
          <i>
            !
          </i>
          <span>
            invaild password
          </span>
        </div>
      </mat-form-field>
    </div>

    <!-- <div class="sign-up__row sign-up__comfirm-password">
      <mat-form-field>
        <mat-label>Comfirm Password</mat-label>
        <input type="password" matInput name="ConfirmPassword" formControlName="ConfirmPassword">
        <mat-error *ngIf="signUpForm.get('ConfirmPassword').hasError('passwordMustMatch')">
          password not match comfirm password
        </mat-error>
        <mat-error *ngIf="signUpForm.get('ConfirmPassword').hasError('pattern')">
          Please enter password need 8-20 characters and an uppercase letter and a lowercase letter and a number
        </mat-error>
      </mat-form-field>
    </div> -->

    <div class="sign-up__nameBlock">
      <div class="sign-up__nameBlock_infoField">
        <label class="itemLabel"> {{"SIGNUPDIALOG.FIRSTNAME"|translate}}</label>
        <mat-form-field class="sign-up__firstname">
          <input type="text" matInput name="firstname" formControlName="firstname">
          <div *ngIf="signUpForm.get('firstname').touched && signUpForm.get('firstname').hasError('required')"
            class="sign-up__error-message">
            <i>
              !
            </i>
            <span>
              Required
            </span>
          </div>
          <div *ngIf="signUpForm.get('firstname').hasError('pattern')" class="sign-up__error-message">
            <i>
              !
            </i>
            <span>
              Please enter english letters
            </span>
          </div>
        </mat-form-field>
      </div>

      <div class="sign-up__nameBlock_infoField">
        <label class="itemLabel">{{"SIGNUPDIALOG.LASTNAME"|translate}}</label>
        <mat-form-field class="sign-up__lastname">
          <input type="text" matInput name="lastname" formControlName="lastname">
          <div *ngIf="signUpForm.get('lastname').touched && signUpForm.get('lastname').hasError('required')"
            class="sign-up__error-message">
            <i>
              !
            </i>
            <span>
              Required
            </span>
          </div>
          <div *ngIf="signUpForm.get('lastname').hasError('pattern')" class="sign-up__error-message">
            <i>
              !
            </i>
            <span>
              Please enter english letters
            </span>
          </div>
        </mat-form-field>
      </div>
    </div>

    <div class="sign-up__agreeBlock">
      <input type="checkbox" checked="checked" name="optIn" formControlName="optIn">
      <p>{{"SIGNUPDIALOG.AGREERECEIEVE"|translate}}</p>
      <!-- <mat-error *ngIf="signUpForm.get('UserAgree').hasError('required')">
        your not have agree ...
      </mat-error> -->
    </div>

    <div class="sign-up__row sign-up__row_center">
      <button class="submitBtn" (click)="submitForm()" [disabled]="submitDisable"
        type="submit">{{"SIGNUPDIALOG.SUBMIT"|translate}}</button>
    </div>
  </form>

  <div class="sign-up__policyBlock">
    <p>{{"SIGNUPDIALOG.AGREETHESE.DETAIL"|translate}}</p>
    <p>
      <a [routerLink]="['/terms']" (click)="TermsAndConditions()" routerLinkActive="active" target="_blank"
        class="sign-up__link">{{"SIGNUPDIALOG.AGREETHESE.TERM&CONDICTIONS"|translate}}</a>
      {{"SIGNUPDIALOG.AGREETHESE.AND"|translate}}
      <a [routerLink]="['/privacy']" (click)="PrivacyPolicy()" routerLinkActive="active" target="_blank"
        class="sign-up__link">{{"SIGNUPDIALOG.AGREETHESE.POLICY"|translate}}</a>
    </p>
  </div>

  <div class="sign-up__third-party-sign-up">
    <p>{{"SIGNUPDIALOG.ALREADYHAVEACCOUNT"|translate}}<a class="sign-up__link"
        (click)="openSignin()">{{"SIGNINDIALOG.SIGNIN" | translate}}</a>.</p>
    <p>{{"SIGNINDIALOG.OTHERWAYS" | translate}}</p>
    <div class="sign-up__use3partyBtnsBlock">
      <button (click)="googleLogin()" [disabled]="this.authService.loginStatus===loginStatus.WaitLogin">
        <img src="multitrim_assets/images/sign-page/google-icon.svg" draggable="false">
      </button>
      <button (click)="microsoftLogin()" [disabled]="this.authService.loginStatus===loginStatus.WaitLogin">
        <img src="multitrim_assets/images/sign-page/microsoft-icon.svg" draggable="false" draggable="false">
      </button>
      <!-- <button  (click)="appleLogin()"
        [disabled]="this.authService.loginStatus===loginStatus.WaitLogin">
        <img src="multitrim_assets/images/sign-page/apple-icon.svg">
      </button> -->
    </div>
  </div>

</div>
