import { protocol } from 'electron';
import { Inject, Injectable } from "@angular/core";
import { MatDialog, MatDialogRef } from "@angular/material/dialog";
import { AuthBehaviorService, AuthService, environment, SignInComponent, SignUpComponent, VerifyEmailComponent } from "uxauth";
import { PlatformServiceService } from "uxshared";
import { Observable } from 'rxjs';
import { UserSettingPopupComponent } from '../../components/user-setting-popup/user-setting-popup.component';

@Injectable()
export class WebBehaviorService implements AuthBehaviorService {
  SignInPage: MatDialogRef<SignInComponent>;
  SignUpPage: MatDialogRef<SignUpComponent>;
  VerifyEmailPage: MatDialogRef<VerifyEmailComponent>;
  authWindow?: any;
  currWindow = null;
  loginBy = null;
  protected _env;
  constructor(
    protected plats: PlatformServiceService,
    protected dialog: MatDialog,
    protected authService: AuthService,
    @Inject(environment) env
  ) {
    this._env = env;
    // console.log(this.currWindow, 'constructor');
  }
  openSetting(url): void {
    this.openSettingPopup();
  }
  async openSettingPage(url): Promise<void> {
    window.open(url);
  }
  openSettingPopup() {
    this.dialog.open(UserSettingPopupComponent, {
      disableClose: true,
      width: '660px',
      minHeight: '208px'
    });
  }
  focusMainWindow(): void {
    window.focus();
  }
  openExtlogin(url, signPopup): any {
    return new Observable(subscriber => {
      const ourl = new URL(url);
      const width = signPopup.getBoundingClientRect().width;
      const top = signPopup.getBoundingClientRect().top;
      const left = signPopup.getBoundingClientRect().left;
      if (!this.loginBy) {
        this.loginBy = ourl.pathname;
      }
      if (this.loginBy && this.loginBy !== ourl.pathname) {
        this.loginBy = ourl.pathname;
        this.currWindow.close();
        const win = window.open(url, '', `width=400, height=650, top=${top}, left=${width + left}`);
        this.currWindow = win;
      }
      if (!this.currWindow) {
        const win = window.open(url, '', `width=400, height=650, top=${top}, left=${width + left}`);
        this.currWindow = win;
      }
      if (this.currWindow !== null) {
        this.currWindow.focus();
      }
      const timer = setInterval(() => {
        if (this.currWindow?.closed) {
          this.currWindow = null;
          this.loginBy = null;
          subscriber.next(false);
          clearInterval(timer);
        }
      }, 500);
    });
  }

  say(): void {
    console.log(this);
    console.log('Im a web');
  }
  openSignInPage(): void {
    this.SignInPage = this.dialog.open(SignInComponent, {
      width: '426px',
      height: '232px',
      disableClose: true
    });
  }
  openSignUpPage(): void {
    if (this.SignInPage && this.SignInPage.id) {
      this.SignInPage.close();
    }
    this.SignUpPage = this.dialog.open(SignUpComponent, {
      disableClose: true,
      width: '350px',
      height: '580px'
    });
  }
  openVerifyEmailPage(): void {
    if (this.SignUpPage && this.SignUpPage.id) {
      this.SignUpPage.close();
    }
    this.VerifyEmailPage = this.dialog.open(VerifyEmailComponent, {
      disableClose: true,
      width: '350px',
      height: '550px'
    });

  }
  closePage(): void {
    if (this.SignInPage && this.SignInPage.id) {
      this.SignInPage.close();
    }
    if (this.SignUpPage && this.SignUpPage.id) {
      this.SignUpPage.close();
    }
    if (this.VerifyEmailPage && this.VerifyEmailPage.id) {
      this.VerifyEmailPage.close();
    }
  }
  actionLogout(): void {
    this.authService.logoutUser();
  }
}
