
import { CommonModule } from '@angular/common';
import { ErrorHandler, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { TokenAuthHttpInterceptor, UXAuthModule } from 'uxauth';
import { ErrorHandlerService, I18nModule } from 'uxshared';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { UXSharedModule } from 'uxshared';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { HomeComponent } from './home/home.component';
import { environment } from 'projects/uxshared/src/environments/environment';
import { HelpComponent } from './help/help.component';
import { NavbarModule } from './navbar/components/navbar/navbar.module';

@NgModule({
  declarations: [
    AppComponent,
    HomeComponent,
    HelpComponent
  ],
  imports: [
    CommonModule,
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    NavbarModule,
    I18nModule,
    UXAuthModule.forRoot(environment),
    UXSharedModule.forRoot(),
  ],
  providers: [
    {
      provide: HTTP_INTERCEPTORS,
      useClass: TokenAuthHttpInterceptor ,
      multi: true,
    },
    {
      provide: ErrorHandler,
      //useClass: ErrorHandlerService
    },
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
