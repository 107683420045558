<div class="verify-email" >
  <div class="verify-email__title">
    <div class="verify-email__title-txt">
      {{"SIGNUPDIALOG.SIGNUP"|translate}}
    </div>
    <img src="multitrim_assets/images/sign-page/sign_close.svg" (click)="close()" draggable="false">
  </div>




  <div class="verify-email__content">
    <div class="verify-email__row verify-email__row_center">
      <div class="mainContentLabel">{{"VERIFYDIALOG.VERIFYMAIL"|translate}}</div>
      <p>{{"VERIFYDIALOG.HASSENTTO"|translate}}</p>
      <p *ngIf="this.authService.usersModel.getCacheUser().email">{{this.authService.usersModel.getCacheUser().email}}</p>
      <p *ngIf="!this.authService.usersModel.getCacheUser().email">{{"VERIFYDIALOG.UMAIL"|translate}}</p>
    </div>

    <div class="verify-email__stepBlock">
      <h4><span>{{"VERIFYDIALOG.STEP"|translate}}</span> 1</h4>
      <p>
        {{"VERIFYDIALOG.FIRSTDESC"|translate}}
      </p>
    </div>
    <div class="verify-email__stepBlock">
      <h4><span>{{"VERIFYDIALOG.STEP"|translate}}</span> 2</h4>
      <p>
        {{"VERIFYDIALOG.SECDESC"|translate}}
      </p>
    </div>
    <div class="verify-email__stepBlock">
      <h4><span>{{"VERIFYDIALOG.STEP"|translate}}</span> 3</h4>
      <p>
        {{"VERIFYDIALOG.THIRDDESC"|translate}}
      </p>
    </div>


    <div class="verify-email__receiveEmailBlock">
      <div class="verify-email__receiveEmailBlock_description">
        <p>
          {{"VERIFYDIALOG.AINTGOTMAIL"|translate}}
        </p>
      </div>


      <div class="verify-email__receiveEmailBlock_actionBtnBlock" (click)="resendEmail()">

        <!-- <button mat-raised-button (click)="resendEmail()">Resend</button> -->
        <img src="multitrim_assets/images/sign-page/mail.svg" draggable="false">
        <p>{{"VERIFYDIALOG.RESEND"|translate}}</p>
      </div>
      <!-- <button mat-raised-button (click)="resendEmail()">Resend</button> -->
    </div>
    <div class="verify-email__doneBlock">
      <p>{{"VERIFYDIALOG.DONE"|translate}}</p>

      <button class="signInBtn" (click)="openSignin()">{{"SIGNINDIALOG.SIGNIN"|translate}}</button>
    </div>
  </div>
</div>
